import CurrencyFilter from '@fingo/lib/components/filters/CurrencyFilter';
import ControlledSelectMenu from '@fingo/lib/components/menus/ControlledSelectMenu';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const DebtorControlledSelectMenu = ({
  options,
  value,
  setValue,
  onChange,
  currency,
  setCurrency,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <ControlledSelectMenu
      options={options}
      value={value}
      setValue={setValue}
      onChange={onChange}
    />
    <CurrencyFilter currency={currency} setCurrency={setCurrency} />
  </Box>
);

DebtorControlledSelectMenu.propTypes = {
  ...ControlledSelectMenu.propTypes,
  currency: PropTypes.string,
  setCurrency: PropTypes.func,
};

DebtorControlledSelectMenu.defaultProps = {
  ...ControlledSelectMenu.defaultProps,
  currency: '',
  setCurrency: () => {},
};

export default DebtorControlledSelectMenu;

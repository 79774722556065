import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const FileStatusCell = ({ isCompleted }) => (
  <Box direction="row" alignItems="center">
    {isCompleted ? (
      <CheckCircleOutlineIcon sx={{ color: 'green', mr: 1 }} />
    ) : (
      <CancelOutlinedIcon sx={{ color: 'red', mr: 1 }} />
    )}
    <Typography variant="body2">{isCompleted ? 'Completo' : 'Incompleto'}</Typography>
  </Box>
);

FileStatusCell.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
};

export default FileStatusCell;
